import {
    cssVal,
    getDataByCodePostal, getDataBySiren,
    hidden,
    newInput, texteForAssocies,
    texteForGerances, texteForNatureActe,
    visible
} from "@/data/helpers";
import {def} from "@/data/dictionnaire";

export const snc = {
    title: "SNC Société en Nom Collectif",
    texte: "{{statusGroup}} a été constituée la société suivante : <strong>Forme</strong> : {{societyGroup.societeFormeJuridique}} <strong>Dénomination : {{societyGroup.societeDenomination}}</strong>[[ <strong>Sigle</strong> : {{societyGroup.sigle}}]]. <strong>Siège</strong> : {{siegeSocialGroup.societeAdresse}} [[{{siegeSocialGroup.societeAdresse2}}]] {{siegeSocialGroup.societeCodePostal}} {{siegeSocialGroup.societeVille}}. <strong>Objet</strong> : {{societyGroup.objet}}. <strong>Durée</strong> : {{societyGroup.duree}} ans à compter de <strong>l'immatriculation au Rcs</strong> {{siegeSocialGroup.societeRcsVille}}. <strong>Capital</strong> : {{societyGroup.typeCapital}}. <strong>Gérance</strong> : {{gerancesGroup}} [[, {{cogerancesGroup}}]]. <strong>Associés</strong> : {{associesGroup_1}} [[. {{associesGroup_2}} [[. {{associesGroup_3}} [[. {{associesGroup_4}} [[. {{associesGroup_5}}]]]]]]]] [[. <strong>Commissaires aux comptes : Titulaire</strong> : {{commissaireAuxComptesGroup.indiquez_1}} [[<strong>Suppléant</strong> : {{commissaireAuxComptesGroup.indiquez_2}}]]]].",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "associesGroup_1":
                tmpText = texteForAssocies(varText, 'associesGroup_1');
                break;
            case "associesGroup_2":
                tmpText = texteForAssocies(varText, 'associesGroup_2');
                break;
            case "associesGroup_3":
                tmpText = texteForAssocies(varText, 'associesGroup_3');
                break;
            case "associesGroup_4":
                tmpText = texteForAssocies(varText, 'associesGroup_4');
                break;
            case "associesGroup_5":
                tmpText = texteForAssocies(varText, 'associesGroup_5');
                break;
            case "statusGroup":
                tmpText = texteForNatureActe(varText);
                break;
            case "gerancesGroup":
                tmpText = texteForGerances(varText);
                break;
            case "cogerancesGroup":
                tmpText = texteForGerances(varText, 'cogerancesGroup');
                break;
            case "societyGroup.typeCapital":
                if(varText['societyGroup.typeCapital'] === 'variable') {
                    tmpText = `variable, initial : ${cssVal(varText["societyGroup.societeCapitalInitial"])} €, minimum : ${cssVal(varText["societyGroup.capitalMinimum"])} €`;
                } else {
                    tmpText = ` ${cssVal(varText["societyGroup.societeCapital"])} €`
                }
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        siegeSocialGroup: [{}],
        gerancesGroup: [{}],
        cogerancesGroup: [{}],
        associesGroup_1: [{}],
        associesGroup_2: [{}],
        associesGroup_3: [{}],
        associesGroup_4: [{}],
        associesGroup_5: [{}],
    },
    schema: [
        {
            //0
            name: "statusGroup",
            label: "Les statuts",
            type: "group",
            value: [{}],
            children: [
                newInput(def.natureActe, '_required', {
                    on: {
                        change: (val) => {
                            val = val.target.value;
                            snc.schema[0].children[3] = val === "acte authentique" ? visible(snc.schema[0].children[3]) : hidden(snc.schema[0].children[3]);
                        }
                    }
                }),
                newInput(def.dateActe, '_required'),
                newInput(def.lieuActe, '_required'),
                newInput(def.recuPar, '_required', {}, 'hidden'),
            ],
        },
        {
            //1
            name: "societyGroup",
            label: "La société",
            type: "group",
            value: [{}],
            children: [
                newInput(def.formeSocieteSnc, '_required', {name: 'societeFormeJuridique'}, 'disabled'),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}),
                newInput(def.sigle),
                newInput(def.objet, '_required'),
                newInput(def.duree, '_required'),
                newInput(def.typeCapital, '_required', {
                    on: {
                        input: (val) => {
                            snc.schema[1].children[5] = val === "fixe" ? visible(snc.schema[1].children[5]) : hidden(snc.schema[1].children[5]);
                            snc.schema[1].children[6] = val === "variable" ? visible(snc.schema[1].children[6]) : hidden(snc.schema[1].children[6]);
                            snc.schema[1].children[7] = val === "variable" ? visible(snc.schema[1].children[7]) : hidden(snc.schema[1].children[7]);
                        }
                    }
                }),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}),
                newInput(def.capitalInitial, '_required', {name: 'societeCapitalInitial'}, 'hidden'),
                newInput(def.capitalMinimum, '_required', {}, 'hidden'),
            ]
        },
        {
            //2
            name: "siegeSocialGroup",
            label: "Adresse du siège social",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.schema[2].children[3].options = data.options;
                            snc.model.siegeSocialGroup[0].societeVille = data.ville_slug;
                            snc.model.siegeSocialGroup[0].societeRcsVille = data.greffe;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}, 'disabled'),
            ]
        },
        {
            //3
            name: "gerancesGroup",
            label: "La gérance",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            snc.schema[3].children[1] = val === "physique" ? visible(snc.schema[3].children[1]) : hidden(snc.schema[3].children[1]);
                            snc.schema[3].children[2] = val === "physique" ? visible(snc.schema[3].children[2]) : hidden(snc.schema[3].children[2]);

                            snc.schema[3].children[3] = val === "morale" ? visible(snc.schema[3].children[3]) : hidden(snc.schema[3].children[3]);
                            snc.schema[3].children[4] = val === "morale" ? visible(snc.schema[3].children[4]) : hidden(snc.schema[3].children[4]);
                            snc.schema[3].children[5] = val === "morale" ? visible(snc.schema[3].children[5]) : hidden(snc.schema[3].children[5]);
                            snc.schema[3].children[6] = val === "morale" ? visible(snc.schema[3].children[6]) : hidden(snc.schema[3].children[6]);
                            snc.schema[3].children[7] = val === "morale" ? visible(snc.schema[3].children[7]) : hidden(snc.schema[3].children[7]);
                            snc.schema[3].children[8] = val === "morale" ? visible(snc.schema[3].children[8]) : hidden(snc.schema[3].children[8]);

                        }
                    }
                }),
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.model.gerancesGroup[0] = Object.assign(snc.model.gerancesGroup[0], data.values.all);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            snc.schema[3].children[12].options = dataCP.options;
                            snc.model.gerancesGroup[0].ville = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),
                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.sigle, '', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.schema[3].children[12].options = data.options;
                            snc.model.gerancesGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //4
            label: "Ajouter la Co-gérance",
            type: "button",
            on: {
                click: () => {
                    snc.schema[4].type = "hidden";
                    snc.schema[4].label = "";

                    snc.schema[5].type = "group";
                    snc.schema[5].label = "La Co-gérance";

                    snc.schema[6].type = "button";
                    snc.schema[6].label = "- Supprimer la Co-gérance";
                }
            }
        },
        {
            //5
            name: "cogerancesGroup",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            snc.schema[5].children[1] = val === "physique" ? visible(snc.schema[5].children[1]) : hidden(snc.schema[5].children[1]);
                            snc.schema[5].children[2] = val === "physique" ? visible(snc.schema[5].children[2]) : hidden(snc.schema[5].children[2]);

                            snc.schema[5].children[3] = val === "morale" ? visible(snc.schema[5].children[3]) : hidden(snc.schema[5].children[3]);
                            snc.schema[5].children[4] = val === "morale" ? visible(snc.schema[5].children[4]) : hidden(snc.schema[5].children[4]);
                            snc.schema[5].children[5] = val === "morale" ? visible(snc.schema[5].children[5]) : hidden(snc.schema[5].children[5]);
                            snc.schema[5].children[6] = val === "morale" ? visible(snc.schema[5].children[6]) : hidden(snc.schema[5].children[6]);
                            snc.schema[5].children[7] = val === "morale" ? visible(snc.schema[5].children[7]) : hidden(snc.schema[5].children[7]);
                            snc.schema[5].children[8] = val === "morale" ? visible(snc.schema[5].children[8]) : hidden(snc.schema[5].children[8]);

                        }
                    }
                }),
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.model.cogerancesGroup[0] = Object.assign(snc.model.cogerancesGroup[0], data.values.all);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            snc.schema[5].children[12].options = dataCP.options;
                            snc.model.cogerancesGroup[0].ville = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),
                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.sigle, '', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.schema[5].children[12].options = data.options;
                            snc.model.cogerancesGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //6
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    snc.schema[4].type = "button";
                    snc.schema[4].label = "+ Ajouter la Co-gérance";

                    snc.schema[5].type = "hidden";
                    snc.schema[5].label = "";

                    snc.schema[6].type = "hidden";
                    snc.schema[6].label = "";
                }
            }
        },

        /* --------------------------------------------------------------- */
        {
            //7
            name: "associesGroup_1",
            label: "Associés",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            snc.schema[7].children[1] = val === "physique" ? visible(snc.schema[7].children[1]) : hidden(snc.schema[7].children[1]);
                            snc.schema[7].children[2] = val === "physique" ? visible(snc.schema[7].children[2]) : hidden(snc.schema[7].children[2]);

                            snc.schema[7].children[3] = val === "morale" ? visible(snc.schema[7].children[3]) : hidden(snc.schema[7].children[3]);
                            snc.schema[7].children[4] = val === "morale" ? visible(snc.schema[7].children[4]) : hidden(snc.schema[7].children[4]);
                            snc.schema[7].children[5] = val === "morale" ? visible(snc.schema[7].children[5]) : hidden(snc.schema[7].children[5]);
                            snc.schema[7].children[6] = val === "morale" ? visible(snc.schema[7].children[6]) : hidden(snc.schema[7].children[6]);
                            snc.schema[7].children[7] = val === "morale" ? visible(snc.schema[7].children[7]) : hidden(snc.schema[7].children[7]);
                            snc.schema[7].children[8] = val === "morale" ? visible(snc.schema[7].children[8]) : hidden(snc.schema[7].children[8]);

                        }
                    }
                }),
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.model.associesGroup_1[0] = data.values.all;
                            snc.model.associesGroup_1[0].typePersonne = 'morale';

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            snc.schema[7].children[12].options = dataCP.options;
                            snc.model.associesGroup_1[0].ville = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),
                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.sigle, '', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.schema[7].children[12].options = data.options;
                            snc.model.associesGroup_1[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //8
            label: "+ Ajouter un associé",
            type: "button",
            on: {
                click: () => {
                    /**
                     * 1. le btn add disparait
                     * 2. le group apparait
                     * 3. le btn del apparait
                     * 4. le new btn add apparait
                     * */
                    //btn add
                    snc.schema[8].type = "hidden";
                    snc.schema[8].label = "";

                    //group 2
                    snc.schema[9].type = "group";
                    snc.schema[9].label = "Associé 2";

                    //btn del
                    snc.schema[10].type = "button";
                    snc.schema[10].label = "- Supprimer un associé";

                    //btn add
                    snc.schema[11].type = "button";
                    snc.schema[11].label = "+ ajouter un associé";
                }
            }
        },
        {
            //9
            name: "associesGroup_2",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            snc.schema[9].children[1] = val === "physique" ? visible(snc.schema[9].children[1]) : hidden(snc.schema[9].children[1]);
                            snc.schema[9].children[2] = val === "physique" ? visible(snc.schema[9].children[2]) : hidden(snc.schema[9].children[2]);

                            snc.schema[9].children[3] = val === "morale" ? visible(snc.schema[9].children[3]) : hidden(snc.schema[9].children[3]);
                            snc.schema[9].children[4] = val === "morale" ? visible(snc.schema[9].children[4]) : hidden(snc.schema[9].children[4]);
                            snc.schema[9].children[5] = val === "morale" ? visible(snc.schema[9].children[5]) : hidden(snc.schema[9].children[5]);
                            snc.schema[9].children[6] = val === "morale" ? visible(snc.schema[9].children[6]) : hidden(snc.schema[9].children[6]);
                            snc.schema[9].children[7] = val === "morale" ? visible(snc.schema[9].children[7]) : hidden(snc.schema[9].children[7]);
                            snc.schema[9].children[8] = val === "morale" ? visible(snc.schema[9].children[8]) : hidden(snc.schema[9].children[8]);

                        }
                    }
                }),
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);

                            snc.schema[9].children[12].options = dataCP.options;
                            snc.model.associesGroup_2[0] = data.values.all;
                            snc.model.associesGroup_2[0].typePersonne = 'morale';
                            snc.model.associesGroup_2[0].ville = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),
                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.sigle, '', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.schema[9].children[12].options = data.options;
                            snc.model.associesGroup_2[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //10 btn del
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn del disparait
                     * 2. le group disparait
                     * 3. le new btn add disparait
                     * 4. le old btn add apparait
                     * */
                    //btn del this
                    snc.schema[10].type = "hidden";
                    snc.schema[10].label = "";

                    //group 2
                    snc.schema[9].type = "hidden";
                    snc.schema[9].label = "";

                    //new btn add
                    snc.schema[11].type = "hidden";
                    snc.schema[11].label = "";

                    //old btn add
                    snc.schema[8].type = "button";
                    snc.schema[8].label = "+ ajouter un associé";
                }
            }
        },
        {
            //11 btn add
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn add disparait
                     * 2. le old btn del disparait
                     * 3. le group apparait
                     * 4. le new btn add apparait
                     * 5. le new btn del apparait
                     * */
                    //btn add
                    snc.schema[11].type = "hidden";
                    snc.schema[11].label = "";

                    //btn del
                    snc.schema[10].type = "hidden";
                    snc.schema[10].label = "";

                    //group 2
                    snc.schema[12].type = "group";
                    snc.schema[12].label = "Associé 3";

                    //btn add
                    snc.schema[14].type = "button";
                    snc.schema[14].label = "+ ajouter un associé";

                    //btn del
                    snc.schema[13].type = "button";
                    snc.schema[13].label = "- supprimer un associé";
                }
            }
        },
        {
            //12 group 3
            name: "associesGroup_3",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            snc.schema[12].children[1] = val === "physique" ? visible(snc.schema[12].children[1]) : hidden(snc.schema[12].children[1]);
                            snc.schema[12].children[2] = val === "physique" ? visible(snc.schema[12].children[2]) : hidden(snc.schema[12].children[2]);

                            snc.schema[12].children[3] = val === "morale" ? visible(snc.schema[12].children[3]) : hidden(snc.schema[12].children[3]);
                            snc.schema[12].children[4] = val === "morale" ? visible(snc.schema[12].children[4]) : hidden(snc.schema[12].children[4]);
                            snc.schema[12].children[5] = val === "morale" ? visible(snc.schema[12].children[5]) : hidden(snc.schema[12].children[5]);
                            snc.schema[12].children[6] = val === "morale" ? visible(snc.schema[12].children[6]) : hidden(snc.schema[12].children[6]);
                            snc.schema[12].children[7] = val === "morale" ? visible(snc.schema[12].children[7]) : hidden(snc.schema[12].children[7]);
                            snc.schema[12].children[8] = val === "morale" ? visible(snc.schema[12].children[8]) : hidden(snc.schema[12].children[8]);

                        }
                    }
                }),
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.model.associesGroup_3[0] = data.values.all;
                            snc.model.associesGroup_3[0].typePersonne = 'morale';

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            snc.schema[12].children[12].options = dataCP.options;
                            snc.model.associesGroup_3[0].ville = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),
                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.sigle, '', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.schema[12].children[12].options = data.options;
                            snc.model.associesGroup_3[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //13 btn del
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn del disparait
                     * 2. le group disparait
                     * 3. le new btn add disparait
                     * 4. le old btn add apparait
                     * 5. le old btn del apparait
                     * */
                    //btn del this
                    snc.schema[13].type = "hidden";
                    snc.schema[13].label = "";

                    //group 2
                    snc.schema[12].type = "hidden";
                    snc.schema[12].label = "";

                    //new btn add
                    snc.schema[14].type = "hidden";
                    snc.schema[14].label = "";

                    //old btn add
                    snc.schema[11].type = "button";
                    snc.schema[11].label = "+ ajouter un associé";

                    //old btn del
                    snc.schema[10].type = "button";
                    snc.schema[10].label = "- supprimer un associé";
                }
            }
        },
        {
            //14 btn add
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn add disparait
                     * 2. le old btn del disparait
                     * 3. le group apparait
                     * 4. le new btn add apparait
                     * 5. le new btn del apparait
                     * */
                    //btn add
                    snc.schema[14].type = "hidden";
                    snc.schema[14].label = "";

                    //btn del
                    snc.schema[13].type = "hidden";
                    snc.schema[13].label = "";

                    //group 2
                    snc.schema[15].type = "group";
                    snc.schema[15].label = "Associé 4";

                    //btn add
                    snc.schema[17].type = "button";
                    snc.schema[17].label = "+ ajouter un associé";

                    //btn del
                    snc.schema[16].type = "button";
                    snc.schema[16].label = "- supprimer un associé";
                }
            }
        },
        {
            //15 group 4
            name: "associesGroup_4",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            snc.schema[15].children[1] = val === "physique" ? visible(snc.schema[15].children[1]) : hidden(snc.schema[15].children[1]);
                            snc.schema[15].children[2] = val === "physique" ? visible(snc.schema[15].children[2]) : hidden(snc.schema[15].children[2]);

                            snc.schema[15].children[3] = val === "morale" ? visible(snc.schema[15].children[3]) : hidden(snc.schema[15].children[3]);
                            snc.schema[15].children[4] = val === "morale" ? visible(snc.schema[15].children[4]) : hidden(snc.schema[15].children[4]);
                            snc.schema[15].children[5] = val === "morale" ? visible(snc.schema[15].children[5]) : hidden(snc.schema[15].children[5]);
                            snc.schema[15].children[6] = val === "morale" ? visible(snc.schema[15].children[6]) : hidden(snc.schema[15].children[6]);
                            snc.schema[15].children[7] = val === "morale" ? visible(snc.schema[15].children[7]) : hidden(snc.schema[15].children[7]);
                            snc.schema[15].children[8] = val === "morale" ? visible(snc.schema[15].children[8]) : hidden(snc.schema[15].children[8]);

                        }
                    }
                }),
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            snc.schema[15].children[12].options = dataCP.options;

                            snc.model.associesGroup_4[0] = data.values.all;
                            snc.model.associesGroup_4[0].typePersonne = 'morale';
                            snc.model.associesGroup_4[0].ville = dataCP.ville_slug;

                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),
                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.sigle, '', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.schema[15].children[12].options = data.options;
                            snc.model.associesGroup_4[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //16 btn del
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn del disparait
                     * 2. le group disparait
                     * 3. le new btn add disparait
                     * 4. le old btn add apparait
                     * 5. le old btn del apparait
                     * */
                    //btn del this
                    snc.schema[16].type = "hidden";
                    snc.schema[16].label = "";

                    //group 2
                    snc.schema[15].type = "hidden";
                    snc.schema[15].label = "";

                    //new btn add
                    snc.schema[17].type = "hidden";
                    snc.schema[17].label = "";

                    //old btn add
                    snc.schema[14].type = "button";
                    snc.schema[14].label = "+ ajouter un associé";

                    //old btn del
                    snc.schema[13].type = "button";
                    snc.schema[13].label = "- supprimer un associé";
                }
            }
        },
        {
            //17 btn add
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn add disparait
                     * 2. le old btn del disparait
                     * 3. le group apparait
                     * 4. le new btn add apparait
                     * */
                    //btn add
                    snc.schema[17].type = "hidden";
                    snc.schema[17].label = "";

                    //btn del
                    snc.schema[16].type = "hidden";
                    snc.schema[16].label = "";

                    //group 2
                    snc.schema[18].type = "group";
                    snc.schema[18].label = "Associé 5";

                    //btn del
                    snc.schema[19].type = "button";
                    snc.schema[19].label = "- supprimer un associé";
                }
            }
        },
        {
            //18 group 5
            name: "associesGroup_5",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            snc.schema[18].children[1] = val === "physique" ? visible(snc.schema[18].children[1]) : hidden(snc.schema[18].children[1]);
                            snc.schema[18].children[2] = val === "physique" ? visible(snc.schema[18].children[2]) : hidden(snc.schema[18].children[2]);

                            snc.schema[18].children[3] = val === "morale" ? visible(snc.schema[18].children[3]) : hidden(snc.schema[18].children[3]);
                            snc.schema[18].children[4] = val === "morale" ? visible(snc.schema[18].children[4]) : hidden(snc.schema[18].children[4]);
                            snc.schema[18].children[5] = val === "morale" ? visible(snc.schema[18].children[5]) : hidden(snc.schema[18].children[5]);
                            snc.schema[18].children[6] = val === "morale" ? visible(snc.schema[18].children[6]) : hidden(snc.schema[18].children[6]);
                            snc.schema[18].children[7] = val === "morale" ? visible(snc.schema[18].children[7]) : hidden(snc.schema[18].children[7]);
                            snc.schema[18].children[8] = val === "morale" ? visible(snc.schema[18].children[8]) : hidden(snc.schema[18].children[8]);

                        }
                    }
                }),
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.model.associesGroup_5[0] = data.values.all;
                            snc.model.associesGroup_5[0].typePersonne = 'morale';

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            snc.schema[18].children[12].options = dataCP.options;
                            snc.model.associesGroup_5[0].ville = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),
                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.sigle, '', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            snc.schema[18].children[12].options = data.options;
                            snc.model.associesGroup_5[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //19 btn del
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn del disparait
                     * 2. le group disparait
                     * 3. le new btn add disparait
                     * 4. le old btn add apparait
                     * 5. le old btn del apparait
                     * */
                    //btn del this
                    snc.schema[19].type = "hidden";
                    snc.schema[19].label = "";

                    //group 2
                    snc.schema[18].type = "hidden";
                    snc.schema[18].label = "";

                    //old btn add
                    snc.schema[17].type = "button";
                    snc.schema[17].label = "+ ajouter un associé";

                    //old btn del
                    snc.schema[16].type = "button";
                    snc.schema[16].label = "- supprimer un associé";
                }
            }
        },
    ]
}